var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[_c('div',{ref:"containerEl",staticClass:"overflow-auto",class:[
      _vm.maxHeight,
      _vm.maxWidth,
      {
        'cursor-grab': _vm.xScrollable || _vm.yScrollable,
        'scrollbar-invisible': !_vm.scrollbarVisible,
      } ],style:(_vm.hideScrollbarStyle)},[_vm._t("default",null,null,{
        xScrollable: _vm.xScrollable,
        yScrollable: _vm.yScrollable,
        scrollLeft: _vm.scrollLeft,
        scrollRight: _vm.scrollRight,
        scrollTop: _vm.scrollTop,
        scrollBottom: _vm.scrollBottom,
      })],2),_vm._v(" "),(_vm.scrollShadow)?[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.state.scrollLeft > 0),expression:"state.scrollLeft > 0"}],staticClass:"absolute inset-y-0 left-0 w-25 bg-gradient-to-l from-gw-transparent to-white pointer-events-none"}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.state.scrollLeft + _vm.state.clientWidth < _vm.state.scrollWidth),expression:"state.scrollLeft + state.clientWidth < state.scrollWidth"}],staticClass:"absolute inset-y-0 right-0 w-25 bg-gradient-to-r from-gw-transparent to-white pointer-events-none"})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }